import React from 'react'

type Props = {
  total: number
  current: number
  onChangePage: (page: number) => void
  maxPages?: number
}

export default function Pagination({
  total,
  current,
  onChangePage,
  maxPages,
}: Props) {
  const items = []

  for (let i = 1; i <= total; i++) {
    if (maxPages && i > maxPages) {
      break
    }

    items.push(
      <li key={i} className={'page-item ' + (current == i && 'active')}>
        <button
          type='button'
          className='page-link'
          onClick={() => onChangePage(i)}
        >
          {i}
        </button>
      </li>
    )
  }

  return (
    <nav>
      <ul className='pagination pagination-sm'>
        <li className={'page-item ' + (current <= 1 && 'disabled')}>
          <button
            type='button'
            className='page-link'
            onClick={() => onChangePage(current - 1)}
          >
            {'<'}
          </button>
        </li>
        {items}
        <li className={'page-item ' + (current >= total && 'disabled')}>
          <button
            type='button'
            className='page-link'
            onClick={() => onChangePage(current + 1)}
          >
            {'>'}
          </button>
        </li>
      </ul>
    </nav>
  )
}
