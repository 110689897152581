import axios from 'axios'
import useSWR from 'swr'

export default function usePlace(url: string, page: number, rows?: number) {
  const fetcher = (url: string) => axios.get(url).then((value) => value.data)

  const { data, error } = useSWR<{ pages: number; data: App.Place[] }>(
    `${url}&per=${(rows || 1) * 3}&page=${page}`,
    fetcher
  )

  return {
    data,
    error,
  }
}
