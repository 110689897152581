import React from 'react'
import arrayGroup from 'utils/array_group'
import PlaceCard from './place_card'

type Props = {
  places: App.Place[]
  loading?: boolean
}

export default function PlaceCardShowcase({ places, loading }: Props) {
  const rows = arrayGroup(places, 3).map((row, index) => (
    <div className='row' key={index}>
      {row.map((place, _index) => (
        <div className='col-md-4' key={_index}>
          <PlaceCard data={place} loading={loading} />
        </div>
      ))}
    </div>
  ))

  return <div className='p-2'>{rows}</div>
}
