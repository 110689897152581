import React, { useEffect, useRef, useState } from 'react'
import placeholderImage from 'utils/placeholder_image'

type Props = {
  data: App.Place
  loading?: boolean
}

export default function PlaceCard({ data, loading }: Props) {
  const [imageLoaded, setImageLoaded] = useState(false)
  const imgRef = useRef<HTMLImageElement>(null)
  useEffect(() => {
    if (imgRef.current?.complete) {
      setImageLoaded(true)
    }
  }, [])

  const pictureUrl = data.pictures[0].thumb.url || placeholderImage

  return (
    <div className='card place-card bg-transparent my-2'>
      <div className='card-image position-relative shadow'>
        <img
          className='card-img-top position-absolute'
          loading='lazy'
          src={pictureUrl}
          onLoad={() => setImageLoaded(true)}
        />
        <img
          className='card-img-top'
          src={placeholderImage}
          style={{ opacity: imageLoaded ? 0 : 1 }}
        />
        {(loading || !imageLoaded) && (
          <div className='position-absolute top-50 start-50 translate-middle'>
            <div className='spinner-border text-primary'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        )}
        <h6 className='position-absolute top-0 start-0 m-3'>
          <span className='badge bg-dark fw-light me-2 py-2 px-3'>
            COD. {data.code}
          </span>
          <span className='badge bg-primary fw-light py-2 px-3'>
            {data.i18n.rent.toUpperCase()}
          </span>
        </h6>
      </div>
      <div className='card-body'>
        <div className='m-2'>
          <span className='d-inline-block me-4'>
            <img className='me-2' src='/beds.svg' />
            <span>{data.place_info.room_count}</span>
          </span>
          <span className='d-inline-block me-4'>
            <img className='me-2' src='/shower.svg' />
            <span>{data.place_info.bathroom_count}</span>
          </span>
          <span className='d-inline-block me-4'>
            <img className='me-2' src='/garage.svg' />
            <span>{data.place_info.garage_count}</span>
          </span>
        </div>
        <h5 className='card-title place-card-title'>
          <a className='text-body stretched-link' href={data.url}>
            {data.title}
          </a>
        </h5>
        <h6 className='card-subtitle place-card-subtitle'>{data.community}</h6>
        <div className='text-primary fw-bold fs-4'>{data.i18n.price}</div>
      </div>
    </div>
  )
}
