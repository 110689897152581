export default function ObjToQuery(
  params: { [key: string]: string },
  removeEmpty?: boolean
) {
  let str = ''
  for (const key in params) {
    if (params[key] == null) {
      continue
    }
    if (removeEmpty && params[key] === '') {
      continue
    }

    if (str != '') {
      str += '&'
    }
    str += key + '=' + encodeURIComponent(params[key])
  }
  return str
}
