import React, { forwardRef } from 'react'

export interface SelectInputProps extends React.HTMLProps<HTMLSelectElement> {
  label?: string
  options?: { label: string; value: string }[]
}

const Select = forwardRef<HTMLSelectElement, SelectInputProps>((props, ref) => {
  const selectProps = { ...props }
  delete selectProps.options

  const options = [...(props.options || [])]
  if (props.placeholder) {
    options.unshift({ label: props.placeholder, value: '' })
  }

  return (
    <div className='mb-3'>
      <label className='form-label' htmlFor={props.id}>
        {props.label}
      </label>
      <select className='form-select' {...selectProps} ref={ref}>
        {options.map(({ label, value }, index) => (
          <option key={index} value={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
  )
})

export default Select
