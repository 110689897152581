import React from 'react'
import SearchBox, { Options } from './search_form'
import Turbolinks from 'turbolinks'
import ObjToQuery from 'utils/obj_to_query'

type Props = {
  url: string
  options: Options
}

export default function SearchLink({ url, options }: Props) {
  return (
    <SearchBox
      onSubmit={(data) => Turbolinks.visit(url + '?' + ObjToQuery(data, true))}
      options={options}
    />
  )
}
