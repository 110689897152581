import React from 'react'
import PlaceCardShowcase from './place_card_showcase'

import BugIcon from 'bootstrap-icons/icons/bug-fill.svg'
import Centered from 'utils/centered'
import usePlace from 'utils/hooks/use_place'
import fakePlaces from 'utils/fake_place'

type Props = {
  url: string
  page: number
  rows?: number
  hidden?: boolean
}

export default function PlaceFetcher({ url, page, rows, hidden }: Props) {
  const { data, error } = usePlace(url, page, rows)

  // This is for prefetching
  if (hidden) {
    return <></>
  }

  if (error) {
    return (
      <Centered>
        <BugIcon />
      </Centered>
    )
  }
  if (!data) {
    return <PlaceCardShowcase places={fakePlaces((rows || 1) * 3)} loading />
  }

  if (data.data.length == 0) {
    return <div className='p-2'>Nenhum imóvel foi encontrado :(</div>
  }

  return <PlaceCardShowcase places={data.data} />
}
