import React, { useEffect, useState } from 'react'
import ObjToQuery from 'utils/obj_to_query'
import PlacePager from './place_pager'
import SearchBox, { SearchInputs, Options } from './search_form'

type Inputs = Partial<SearchInputs>
type Props = {
  apiUrl: string
  initialData: Inputs
  options: Options
}
export default function SearchResults({ apiUrl, initialData, options }: Props) {
  console.log(initialData)
  const [data, setData] = useState<Inputs>(initialData)
  const currentUrl = (dataParams: Inputs) =>
    apiUrl + '?' + ObjToQuery(dataParams, true)

  useEffect(() => {
    const listener = (event: PopStateEvent) => {
      setData(event.state)
    }
    window.addEventListener('popstate', listener)

    return () => {
      window.removeEventListener('popstate', listener)
    }
  }, [])

  return (
    <>
      <SearchBox
        onSubmit={(data) => {
          setData(data)
          history.pushState(data, '', '?' + ObjToQuery(data, true))
        }}
        defaults={data}
        options={options}
      />
      <div className='mb-4'>
        <h2 className='title fw-bold mb-3'>Resultado da busca</h2>
        <PlacePager rows={6} url={currentUrl(data)} />
      </div>
    </>
  )
}
