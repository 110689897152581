import { Spring, Transition } from '@react-spring/core'
import { animated } from '@react-spring/web'
import React, { useState } from 'react'

function mod(n: number, m: number) {
  return ((n % m) + m) % m
}

type Props = {
  images: { normal: string; thumb: string }[]
}

export default function PlaceImages({ images }: Props) {
  const [currentImage, setCurrentImage] = useState(0)
  const next = () => setCurrentImage(mod(currentImage + 1, images.length))
  const prev = () => setCurrentImage(mod(currentImage - 1, images.length))

  return (
    <div>
      <div className='position-relative'>
        <img src={images[0].normal} className='w-100' style={{ opacity: 0 }} />
        <Transition
          items={images[currentImage]}
          from={{ opacity: 0 }}
          enter={{ opacity: 1 }}
          leave={{ opacity: 0 }}
          delay={200}
        >
          {(styles, item) => (
            <animated.div
              style={styles}
              className='d-block position-absolute top-0 start-0'
            >
              <img src={item.normal} className='w-100' />
            </animated.div>
          )}
        </Transition>
        <button className='carousel-control-prev' type='button' onClick={prev}>
          <span
            className='carousel-control-prev-icon'
            aria-hidden='true'
          ></span>
          <span className='visually-hidden'>Previous</span>
        </button>
        <button className='carousel-control-next' type='button' onClick={next}>
          <span
            className='carousel-control-next-icon'
            aria-hidden='true'
          ></span>
          <span className='visually-hidden'>Next</span>
        </button>
      </div>
      <div className='row my-3'>
        {images.map((image, index) => (
          <div
            key={index}
            className='col-6 col-sm-4 col-md-2 btn'
            onClick={() => setCurrentImage(index)}
          >
            <Spring
              to={
                index == currentImage
                  ? {
                      y: -8,
                      opacity: 1,
                      boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.4)',
                    }
                  : {
                      y: 0,
                      opacity: 0.7,
                      boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.0)',
                    }
              }
            >
              {(styles) => (
                <animated.div style={styles} className='rounded'>
                  <img src={image.thumb} className='w-100 rounded' />
                </animated.div>
              )}
            </Spring>
          </div>
        ))}
      </div>
    </div>
  )
}
