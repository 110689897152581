import React, { useEffect } from 'react'
import { SubmitHandler, useForm, UseFormRegister } from 'react-hook-form'
import Input from './form/input'
import Select from './form/select'

export type SearchInputs = {
  rooms: string
  bathrooms: string
  garages: string
  price: string
  community: string
  pay: string
}

// These have to be snake_case because the data comes from rails
type PriceRange = {
  id: number
  range_for: 'rent' | 'buy'
  price_from: number
  price_to: number
  label: string
}

export type Options = {
  place: {
    communities: string[]
    max_bathrooms: number
    max_rooms: number
    max_garages: number
  }
  price_ranges: PriceRange[]
}

type Props = {
  defaults?: Partial<SearchInputs>
  onSubmit: SubmitHandler<SearchInputs>
  options: Options
}

export default function SearchBox({ defaults, onSubmit, options }: Props) {
  const {
    register: hookRegister,
    handleSubmit,
    reset,
    watch,
  } = useForm<SearchInputs>()

  useEffect(() => {
    reset(defaults)
  }, [defaults])

  const register: UseFormRegister<SearchInputs> = (name) => ({
    id: 'search-' + name,
    defaultValue: defaults && defaults[name],
    ...hookRegister(name),
  })

  const payValue = watch('pay')

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='row'>
        <div className='col-xl-1 col-lg-2 col-sm-4'>
          <Select
            options={arrayFrom(0, options.place.max_rooms).map((value) => ({
              label: value.toString(),
              value: value.toString(),
            }))}
            placeholder='-'
            label='Quartos'
            {...register('rooms')}
          />
        </div>
        <div className='col-xl-1 col-lg-2 col-sm-4'>
          <Select
            options={arrayFrom(0, options.place.max_bathrooms).map((value) => ({
              label: value.toString(),
              value: value.toString(),
            }))}
            placeholder='-'
            label='Banheiros'
            {...register('bathrooms')}
          />
        </div>
        <div className='col-xl-1 col-lg-2 col-sm-4'>
          <Select
            options={arrayFrom(0, options.place.max_garages).map((value) => ({
              label: value.toString(),
              value: value.toString(),
            }))}
            placeholder='-'
            label='Garagens'
            {...register('garages')}
          />
        </div>
        <div className='col-xl-2 col-lg-2 col-sm-4'>
          <Select
            options={paySelectOptions}
            label='Tipo'
            placeholder='Tudo'
            {...register('pay')}
          />
        </div>
        <div className='col-xl-3 col-lg-4 col-sm-8'>
          <Select
            options={options.price_ranges
              .filter((el) => el.range_for === payValue)
              .map(({ label, id }) => ({ label, value: id.toString() }))}
            placeholder='-'
            label='Preço'
            {...register('price')}
          />
        </div>
        <div className='col-xl-4 col-sm-12'>
          <Select
            options={options.place.communities.map((value) => ({
              label: value,
              value,
            }))}
            label='Bairro'
            placeholder='Tudo'
            {...register('community')}
          />
        </div>
      </div>
      <div className='mb-2'>
        <input
          type='submit'
          value='Buscar'
          className='btn-dark float-md-end btn btn-primary'
        />
      </div>
    </form>
  )
}

const paySelectOptions = [
  { label: 'Alugar', value: 'rent' },
  { label: 'Comprar', value: 'buy' },
]

function arrayFrom(min: number, max: number) {
  const list: number[] = []
  for (var i = min; i <= max; i++) {
    list.push(i)
  }
  return list
}
