import React, { useState } from 'react'
import Centered from 'utils/centered'
import usePlace from 'utils/hooks/use_place'
import Pagination from './place_cards/pagination'
import PlaceFetcher from './place_cards/place_fetcher'

import BugIcon from 'bootstrap-icons/icons/bug-fill.svg'
import Animated from './place_cards/animated'
import PlaceCardShowcase from './place_cards/place_card_showcase'
import fakePlaces from 'utils/fake_place'

type Props = {
  url: string
  rows?: number
  maxPages?: number
}

export default function PlacePager({ url, rows, maxPages }: Props) {
  const [page, pageHandler] = useState(1)

  const { data, error } = usePlace(url, 1, rows)

  if (error) {
    return (
      <Centered>
        <BugIcon />
      </Centered>
    )
  }
  if (!data) {
    return <PlaceCardShowcase places={fakePlaces((rows || 1) * 3)} loading />
  }

  return (
    <div>
      <PlaceFetcher url={url} page={page} rows={rows} />
      {data.pages > 1 && (maxPages == undefined || maxPages > 1) && (
        <Pagination
          total={data.pages}
          current={page}
          onChangePage={pageHandler}
          maxPages={maxPages}
        />
      )}
    </div>
  )
}
