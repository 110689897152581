import React, { forwardRef } from 'react'

interface Props extends React.HTMLProps<HTMLInputElement> {
  label?: string
}

const Input = forwardRef<HTMLInputElement, Props>((props, ref) => {
  return (
    <div className='mb-3'>
      <label className='form-label' htmlFor={props.id}>
        {props.label}
      </label>
      <input className='form-control' {...props} ref={ref} />
    </div>
  )
})

export default Input
