export default function arrayGroup<T>(arr: T[], groupSize: number) {
  const parentArray = []

  const groupCount = Math.ceil(arr.length / groupSize)

  for (let i = 0; i < groupCount; i++) {
    parentArray.push(arr.slice(i * groupSize, (i + 1) * groupSize))
  }

  return parentArray
}
