import React from 'react'

import { useSpring, animated } from 'react-spring'

type Props = {
  leave: boolean
  onRest: () => void
  children: React.ReactElement
}

export default function Animated({ leave, onRest, children }: Props) {
  const props = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 100,
    reset: true,
    reverse: leave,
    onRest,
  })

  return <animated.div style={props}>{children}</animated.div>
}
