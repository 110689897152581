import placeholder_image from "./placeholder_image.ts"

const fakePlace = () => {
  const fakeData: App.Place = {
    id: 0,
    code: '',
    title: '',
    description: '',
    community: '',
    pictures: [
      {
        thumb: {
          url: placeholder_image,
        },
      },
    ],
    rent: false,
    place_info: {
      bathroom_count: 0,
      garage_count: 0,
      room_count: 0,
    },
    i18n: {
      price: '$ 000',
      rent: '-',
      place_type: '-',
    },
  }

  return fakeData
}

export default function fakePlaces(times = 1) {
  const result = []

  for (let i = 0; i < times; i++) {
    result.push(fakePlace())
  }

  return result
}
